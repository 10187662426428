import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/dev.svg';
import self from 'assets/samurai_final.png'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

export const Intro = () => (
  <Wrapper>
    <Header />
    <IntroWrapper as={Container}>
      <Details>
        <h1 style={{ fontFamily: 'serif' }}>Hi,</h1>
        <h4 style={{ fontFamily: 'serif' }}>I'm Smit, a blockchain researcher whose code has safely managed 9-figures in crypto assets across multiple blockchains.</h4>
        <h4 style={{ fontFamily: 'serif' }}>Current focus: building <a style={{ color: '#B0B0B0' }} href="https://x.com/aionera_" target="_blank">Aionera</a>, a crypto research studio (researching + building + investing) in 0 → 1 ideas with 1 → 0 basis point talent.</h4>
        <h4 style={{ fontFamily: 'serif' }}>Experience: <a style={{ color: '#B0B0B0' }} href="https://github.com/smitrajput" target="_blank">github</a>, <a style={{ color: '#B0B0B0' }} href="https://twitter.com/smit__rajput" target="_blank">X (twitter)</a>, <a style={{ color: '#B0B0B0' }} href="https://www.linkedin.com/in/smit-rajput-417517139/" target="_blank">linkedin</a>, <a style={{ color: '#B0B0B0' }} href="https://drive.google.com/file/d/1ovD8aI3-appNaeDognr4V-FHYzDSBxWV/view" target="_blank">resumé</a>.</h4>
        <h4 style={{ fontFamily: 'serif' }}>Interested devs and projects, get in touch: <a style={{ color: '#B0B0B0' }} href="mailto:smitrajputtd@gmail.com">gmail</a>, <a style={{ color: '#B0B0B0' }} href="https://t.me/smit_rajput" target="_blank">telegram</a>.</h4>
        {/* <Button as={AnchorLink} href="#contact"> https://github.com/smitrajput
          Hire me! Why tf not :))
        </Button> */}
      </Details>
      <Thumbnail>
        <img src={self} alt="I'm Smit." />
      </Thumbnail>
    </IntroWrapper>
  </Wrapper>
);